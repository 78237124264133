<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="status">
                            <el-input placeholder="请输入内容" class="inner-input" v-model="pageForm.searchKeyword"
                                      @keyup.enter.native="doSearch">
                                <el-select v-model="pageForm.searchName" slot="prepend" placeholder="查询类型"
                                           style="width: 120px;">
                                    <el-option label="类型编码" value="code"></el-option>
                                    <el-option label="类型名称" value="name"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" size="medium" @click="doSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" size="medium" plain @click="resetDoSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6"
                            style="display: flex;justify-content: flex-end">
                        <el-button type="primary" size="medium" @click="openAdd"><i class="ti-plus"></i> 新增类型
                        </el-button>
                        <!--<el-button @click="delMore" size="medium">批量删除</el-button>-->
                    </el-col>
                </el-row>
            </el-form>
            <el-table
                    :data="tableData"
                    @sort-change='pageOrder'
                    size="small"
                    header-align="center"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :default-sort="{prop: 'opAt', order: 'descending'}"
            >
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column label="类型编码" header-align="left" prop="code"
                                 :show-overflow-tooltip="true">

                </el-table-column>
                <el-table-column label="类型名称" header-align="left" prop="name"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="关联餐饮点ID" header-align="left" prop="siteId"
                                 :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="状态" header-align="left" prop="isOnSale" :formatter="fomatData"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <!--<el-table-column label="更新人" header-align="left" prop="updateBy"-->
                                 <!--:show-overflow-tooltip="true">-->
                <!--</el-table-column>-->
                <!--<el-table-column label="更新时间" header-align="left" prop="updateTime"-->
                                 <!--:show-overflow-tooltip="true">-->
                <!--</el-table-column>-->
                <el-table-column label="排序" header-align="left" prop="sortNum"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                        prop="id"
                        label="操作"
                        width="115">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'edit',row:scope.row}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'delete',row:scope.row}">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="pageSizeChange"
                    @current-change="pageNumberChange"
                    :current-page="pageForm.pageNumber"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="pageForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageForm.totalCount">
            </el-pagination>
        </el-main>
        <form-dialog @close="handleClose('addForm')" title="新增" :dialogVisible="addDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="addForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item prop="siteId" label="餐饮点">
                        <el-select
                                class="inner-input"
                                v-model="formData.siteId"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入关联餐饮点名称"
                                :remote-method="remoteMethod"
                                :loading="loading">
                            <el-option
                                    v-for="item in storeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="code" label="类型编码">
                        <el-input maxlength="100" placeholder="请输入类型编码"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="类型名称">
                        <el-input maxlength="100" placeholder="请输入类型名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="isOnSale" label="上架状态">
                        <el-select v-model="formData.isOnSale" placeholder="请选择上架状态" class="inner-input">
                            <el-option
                                    v-for="item in statusOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item prop="note" label="备注">
                        <el-input maxlength="100" placeholder="请输入备注"
                                  v-model="formData.note"
                                  auto-complete="off" tabindex="9" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="sortNum" label="排序">
                        <el-input maxlength="100" placeholder="请输入排序值"
                                  v-model="formData.sortNum"
                                  auto-complete="off" tabindex="10" type="text"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="handleClose('addForm')">取消</el-button>
                        <el-button type="primary" @click="doAdd">确定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose('editForm')" title="修改" :dialogVisible="editDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item prop="siteId" label="餐饮点">
                        <el-input maxlength="100" placeholder="请选择餐饮点"
                                  v-model="formData.siteId"
                                  auto-complete="off" tabindex="1" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="code" label="类型编码">
                        <el-input maxlength="100" placeholder="请输入类型编码"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="类型名称">
                        <el-input maxlength="100" placeholder="请输入类型名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>

                    <el-form-item prop="isOnSale" label="上架状态">
                        <el-select v-model="formData.isOnSale" placeholder="请选择上架状态" class="inner-input">
                            <el-option
                                    v-for="item in statusOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="note" label="备注">
                        <el-input maxlength="100" placeholder="请输入备注"
                                  v-model="formData.note"
                                  auto-complete="off" tabindex="9" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="sortNum" label="排序">
                        <el-input maxlength="100" placeholder="请输入排序值"
                                  v-model="formData.sortNum"
                                  auto-complete="off" tabindex="10" type="text"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="handleClose('editForm')">取消</el-button>
                        <el-button type="primary" @click="doEdit">确定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
    import formDialog from '../../components/formDialog'
    import * as foodManager from '../../api/foodManager'
    import {foodSiteData} from "../../api/storeArea";

    import moment from 'moment'

    const statusOptions = [
        {
            label: "上架",
            value: 1
        },
        {
            label: '下架',
            value: 2
        }
    ]
    export default {
        name: "foodMaterial",
        components: {formDialog},
        data() {
            return {
                addDialogVisible: false,
                editDialogVisible: false,
                selectData: [],
                pageForm: {
                    searchName: "",
                    searchKeyword: "",
                    pageNumber: 1,
                    pageSize: 10,
                    totalCount: 0,
                    pageOrderName: "opAt",
                    pageOrderBy: "descending"
                },
                tableData: [],
                formData: {
                    siteId: '',
                    code: '',
                    isOnSale: 1,
                    sortNum: 0
                },
                formRules: {
                    siteId: [
                        {required: true, message: '请选择关联餐饮点', trigger: 'blur'}
                    ],
                    code: [
                        {required: true, message: '请输入类型编码', trigger: 'blur'}
                    ],
                    name: [
                        {required: true, message: '请输入类型名称', trigger: 'blur'}
                    ],
                    isOnSale: [
                        {required: true, message: '请选择上架状态', trigger: 'blur'}
                    ],
                    sortNum: [
                        {required: true, message: '请输入排序编号', trigger: 'blur'}
                    ]
                },
                loading: false,
                storeOptions: [],
                statusOptions
            }
        },
        methods: {
            async remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    try {
                        let formData = {
                            searchName: 'name',
                            searchKeyword: query
                        }
                        let {data} = await foodSiteData(formData)
                        this.storeOptions = data.list
                    } catch (e) {

                    }
                    this.loading = false;
                } else {
                    this.storeOptions = [];
                }
            },
            handleClose(form) {
                if (form) {
                    this.$refs[form].resetFields();
                }
                this.addDialogVisible = false
                this.editDialogVisible = false
            },
            pageOrder(column) {//按字段排序
                this.pageForm.pageOrderName = column.prop;
                this.pageForm.pageOrderBy = column.order;
                this.pageData();
            },
            pageNumberChange(val) {//页码更新操作
                this.pageForm.pageNumber = val;
                this.pageData();
            },
            pageSizeChange(val) {//分页大小更新操作
                this.pageForm.pageSize = val;
                this.pageData();
            },
            doSearch() {
                this.pageForm.pageNumber = 1;
                this.pageData();
            },
            resetDoSearch(){
                this.pageForm.searchName = '';
                this.pageForm.searchKeyword = '';
                this.doSearch()
            },
            async pageData() {//加载分页数据
                try {
                    let formData = this.pageForm
                    let {data} = await foodManager.foodTypeData(formData)
                    this.tableData = data.list
                    this.pageForm.totalCount = data.totalCount;
                } catch (e) {
                    console.error(e)
                }
            },
            formatAt(val) {
                if (val > 0)
                    return moment(val * 1000).format("YYYY-MM-DD HH:mm");
                return "";
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            openAdd() {
                this.addDialogVisible = true;
                this.formData = {
                    id: "",
                };//打开新增窗口,表单先清空
                if (this.$refs["addForm"])
                    this.$refs["addForm"].resetFields();
            },
            doAdd() {
                this.$refs["addForm"].validate(async (valid) => {
                    if (valid) {//表单验证通过
                        try {
                            let formData = this.formData
                            let data = await foodManager.foodTypeAddDo(formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.handleClose('addForm')
                            this.pageData();
                        } catch (e) {
                            console.error(e)
                        }
                    }
                });
            },
            doEdit() {
                this.$refs["editForm"].validate(async (valid) => {
                    if (valid) {//表单验证通过
                        try {
                            let data = await foodManager.foodTypeEditDo(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.handleClose('editForm')
                            this.pageData();
                        } catch (e) {
                            console.error(e)
                        }
                    }
                });
            },
            async delMore() {
                if (this.selectData.length == 0) {
                    this.$message({
                        message: "请选择要删除的类型",
                        type: 'warning'
                    });
                    return false;
                }
                var ids = [];
                this.selectData.forEach(function (val) {
                    ids.push(val.id);
                });
                try {
                    await this.$confirm('此操作将删除 ' + ids.length + ' 菜品类型 ', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    let formData = {ids: ids.toString()}
                    let data = await foodManager.foodTypeDeltes(formData)
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                    this.pageData();
                } catch (e) {

                }
            },
            async dropdownCommand(command) {//监听下拉框事件
                if ("edit" == command.type) {
                    try {
                        let {data} = await foodManager.foodTypeEdit(command.row.id)
                        this.formData = data;//加载后台表单数据
                        this.editDialogVisible = true;//打开编辑窗口
                    } catch (e) {

                    }
                }
                if ("delete" == command.type) {
                    try {
                        await this.$confirm('删除后无法恢复，确定删除吗？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await foodManager.foodTypeDelte(command.row.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.pageData();
                    } catch (e) {

                    }
                }
            },
            fomatData(row, column, cellValue, index){

                let {isOnSale} = row
                console.log(isOnSale)
                let status = statusOptions.find(i =>i.value === isOnSale)
                return status?status.label:'--'
            }
        },
        created() {
            this.pageData();
        }
    }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>
